import React from 'react'

import { formatTokenBalance, fromTokenNameToDecimals, fromTokenNameToAddress, fromTokenNameToIcon } from '../tokenUtilities.js'
import { poolConfigs, rewardToken, tokenConfigs } from './contractConfigs.js';
//formatTokenBalance(fromTokenNameToDecimals(poolConfigs["0"]["stake-name"]), poolBalanceYOMOPU)

export function Pool (props) {
    const thePool = poolConfigs[props.poolID]
    const thePoolTokenName = poolConfigs[props.poolID]["stake-name"]
    const thePoolFee = poolConfigs[props.poolID]["fee"]
    const thePoolRate = poolConfigs[props.poolID]["rate"]

    return ( 
      <div className="pool">
        <div className="pool__name">
          <span>Stake: 
            <a href={"https://blockscout.com/xdai/mainnet/tokens/" + fromTokenNameToAddress(thePoolTokenName)} target="_blank">
              <img className={thePoolTokenName}  src={fromTokenNameToIcon(thePoolTokenName)["default"]} /></a></span>
          <span className="arrows"> {"\>\>"} </span>
          <span>Earn: <img src={rewardToken["icon"]["default"]} /></span>
        </div>
        <div className="pool__textName">{thePool["name"]}</div>
        <div className="pool__balance">
          Balance: <strong>{
            formatTokenBalance(
              fromTokenNameToDecimals(thePoolTokenName), 
              props.balance)}</strong>
        </div>
        <div className="pool__rewards">Pending: <strong>{formatTokenBalance(18, props.rewards)}</strong></div>
        <div className="pool__depositFee">Deposit Fee: <strong>{thePoolFee}%</strong></div>
        <div className="pool__controls">
          <button className="pool__btn" onClick={props.minusFunction}>
            -
          </button>
          <button className="pool__btn" onClick={props.plusFunction}>
            +
          </button>
          <button className="pool__btn" onClick={props.harvestFunction}>
            Harvest
          </button>
        </div>
        <div className="pool__rate">{formatTokenBalance(0, 24*60*6*thePoolRate/props.totalAllocPoints)} TEQUILA/day</div>
      </div>
    )
    
  } 