

export const contractConfigs = {
	"generic": {
		"abi": require("../abis/genABI-dai.json"),
		"address": "0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d"
	},
	"chef": {
		"abi": require("../abis/chef.json"),
		"address": "0xAb23368c8fF6e518AE4a114cc10d9a1aC29f9667"
	}
}

export const poolConfigs = {
	"0": {
		"stake-name": "agave",
		"fee": "0.1",
		"name": "Agave Crate",
		"rate": "1"
	}, 
	"1": {
		"stake-name": "honey",
		"fee": "0.1",
		"name": "Honey Jar",
		"rate": "1"
	},
	
	"2": {
		"stake-name": "agaveTequila",
		"fee": "0",
		"name": "Agave Tequila Barrel",
		"rate": "10"
	},
	"3": {
		"stake-name": "honeyTequila",
		"fee": "0",
		"name": "Honey Tequila Barrel",
		"rate": "10"
	},
	"4": {
		"stake-name": "wxdaiTequila",
		"fee": "0.3",
		"name": "xDai Tequila Barrel",
		"rate": "20"
	},
	"5": {
		"stake-name": "tequila",
		"fee": "0",
		"name": "Tequila Bottle",
		"rate": "100"
	},
	"6": {
		"stake-name": "moonwasp",
		"fee": "10",
		"name": "MoonWasp Gold",
		"rate": "100"
	},
	"7": {
		"stake-name": "moonwaspWxdai",
		"fee": "2",
		"name": "MoonWasp Silver",
		"rate": "200"
	},
	"8": {
		"stake-name": "moonwaspHoney",
		"fee": "0.3",
		"name": "MoonWasp Reposado",
		"rate": "100"
	},
	"9": {
		"stake-name": "moonwaspXcomb",
		"fee": "4",
		"name": "MoonWasp Small Batch",
		"rate": "100"
	},
	"10": {
		"stake-name": "moonwaspTequila",
		"fee": "0.3",
		"name": "MoonWasp Top-Shelf",
		"rate": "400"
	},
	"11": {
		"stake-name": "xcombTequila",
		"fee": "1",
		"name": "Farmer's Batch",
		"rate": "50"
	},

}

export const rewardToken = {
	"name": "tequila",
	"displayname": "Tequila",
	"symbol": "TEQUILA",
	"address": "0x31587a605B4cEbe82eaF1e8c3fF60563B0642118",
	"icon": require("../images/token-icons/tequila.png")
}

export const tokenConfigs = {
	"agave": {
		"name": "AGAVE",
		"address": "0x3a97704a1b25F08aa230ae53B352e2e72ef52843",
		"icon": require("../images/token-icons/agve.png"),
		"decimals": 18
	},
	"honey": {
		"name": "HNY",
		"address": "0x71850b7E9Ee3f13Ab46d67167341E4bDc905Eef9",
		"icon": require("../images/token-icons/hny.png"),
		"decimals": 18
	},
	"tequila": {
		"name": "TEQUILA",
		"address": "0x31587a605B4cEbe82eaF1e8c3fF60563B0642118",
		"icon": require("../images/token-icons/tequila.png"),
		"decimals": 18
	},
	"agaveTequila": {
		"name": "AGVE-TEQUILA LP",
		"address": "0x04B6b03043394751fb8DCb4ae6480EC43250E8Ef",
		"icon": require("../images/token-icons/agave-tequila.png"),
		"decimals": 18
	},
	"honeyTequila": {
		"name": "HNY-TEQUILA LP",
		"address": "0x1f7daB673b20Bac617a2540041A64fcdf30Bb906",
		"icon": require("../images/token-icons/honey-tequila.png"),
		"decimals": 18
	},
	"wxdaiTequila": {
		"name": "wxDAI-TEQUILA LP",
		"address": "0x8fC2fE35C0Dd116483D4522B0166028a1eaAD248",
		"icon": require("../images/token-icons/wxdai-tequila.png"),
		"decimals": 18
	},
	"moonwasp": {
		"name": "MOONWASP",
		"address": "0xd4306991f8a5CEF2d2bCB3262224dEd11BD18baD",
		"icon": require("../images/token-icons/wasp-metal.png"),
		"decimals": 9
	},
	"moonwaspWxdai": {
		"name": "MOONWASP-wxDAI LP",
		"address": "0xd8e59bdacd4978d6fad9887e45145421414d8a03",
		"icon": require("../images/token-icons/wasp-wxdai.png"),
		"decimals": 18
	},
	"moonwaspHoney": {
		"name": "MOONWASP-HNY LP",
		"address": "0xf9d1326570d3227e7f7d2749b1c3982366ca731a",
		"icon": require("../images/token-icons/wasp-hny.png"),
		"decimals": 18
	},
	"moonwaspXcomb": {
		"name": "MOONWASP-xCOMB LP",
		"address": "0xf09786d1e1bda9966d7166957c41bd8a66fde7cf",
		"icon": require("../images/token-icons/wasp-xcomb.png"),
		"decimals": 18
	},
	"moonwaspTequila": {
		"name": "MOONWASP-TEQUILA LP",
		"address": "0x1333e0db281206cdb70aafb1d748a3fccb3e4798",
		"icon": require("../images/token-icons/wasp-tequila.png"),
		"decimals": 18
	},
	"xcombTequila": {
		"name": "xCOMB-TEQUILA LP",
		"address": "0xd53b8527e7a2713c9108592d246337da0eadc631",
		"icon": require("../images/token-icons/xcomb-tequila.png"),
		"decimals": 18
	}

}