// Author: Ianoda aka Maxus -- maxus.blog
import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';
import { BigNumber } from '@ethersproject/bignumber';


import { formatTokenBalance, searchAddress, fromTokenNameTo, fromTokenNameToDecimals, fromTokenNameToAddress } from './tokenUtilities.js'

// CUSTOM components 
import './App.scss';
import { Pool } from './components/PoolElement.js';
import { chainMap } from './components/ChainTools.js';
import { contractConfigs, poolConfigs, rewardToken, tokenConfigs } from './components/contractConfigs.js';
import { Pu } from './PoolUtilities.js'

// MATH STUFF
const dec18 = BigNumber.from(10).pow(18)
const CHEF = contractConfigs["chef"]["address"]
const REWARD = rewardToken["address"]

const poolIDs = Object.keys(poolConfigs)

function handleChainChange(chainId) {
      window.location.reload();
    }


function App() {

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     if (window.ethereum.chainId === "0x64") {
        letItRip();

      } else {
        alert("please switch to xDAI")
      }
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])
  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----



  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Logics
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  // this is a reference to the input field
  const theInputRef = React.createRef();

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [theTargetADDY, setTheTargetADDY] = React.useState("");

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [theTargetNAME, setTheTargetNAME] = React.useState("");

  // when a user opens the input overlay,
  // this state gets set to the pool ID of the target pool
  const [theTargetPOOL, setTheTargetPOOL] = React.useState(0);

  // this state manages the display of the input overlay
  const [theInputTOGGLE, setTheInputTOGGLE] = React.useState(false);

  // this function manages the toggling of theInputTOGGLE state
  const toggleInput = () => {
    if (theInputTOGGLE) {
      setTheInputTOGGLE(false)
    } else {
      setTheInputTOGGLE(true)
    }
  }

  // this state manages the intent of the input overlay
  // it should be set to false initially,
  // and then set to either "add" or "remove"
  const [theInputINTENT, setTheInputINTENT] = React.useState(false);

  // this is the web3 instance used throughout the dApp
  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545')


//  END SCRIPT




  // implement Pu class (poolUtilities.js)
  var pu = new Pu(web3, theInputRef)

  // onClick function factory for building appropriate input overlay
  const openInputViewAndDoStuff = (addy, intent, argOneIsAddy = false) => {
  
    let theAddy = (argOneIsAddy) ? addy : fromTokenNameToAddress(poolConfigs[addy.toString()]["stake-name"])
    let theName = (argOneIsAddy) ? addy : poolConfigs[addy.toString()]["stake-name"]
    let thePool = (argOneIsAddy) ? addy : addy.toString()
    return () => {

      toggleInput()
      setTheTargetADDY(theAddy)
      setTheInputINTENT(intent)
      setTheTargetNAME(theName)
      
      setTheTargetPOOL(thePool)
      console.log(theTargetPOOL)
      pu.getBalance(theAddy, (bal) => smartSetBalanceOfTarget(bal))
      pu.checkAllowance(theAddy, (allowance) => smartSetAllowanceOfTarget(allowance))
    }

  }


  // onClick function factory that returns appropriate harvest function
  const returnHarvestFunction = (id) => {
    return () => {
      setIsPending(true)
      pu.harvest(
        id,
        () => {
          setIsPending(false)
          triggerGetPendingRewards(id);
        })
    }     
  }

  // onClick function for depositing to theTargetPOOL
  const triggerDeposit = () => {
    setIsPending(true)
    console.log("target pool: " + theTargetPOOL)
    pu.depositAmount(
      theTargetPOOL,
      () => {
        setIsPending(false)
        toggleInput();
        triggerGetPoolBalance(theTargetPOOL);
        triggerGetPendingRewards(theTargetPOOL);
      }
    )
  }

  // onClick function for withdrawing from theTargetPOOL
  const triggerWithdraw = () => {
    setIsPending(true)
    pu.withdrawAmount(
      theTargetPOOL,
      () => {
        setIsPending(false)
        toggleInput();
        triggerGetPoolBalance(theTargetPOOL);
        triggerGetPendingRewards(theTargetPOOL);
      }
    )
  }

  const setInputRefToMAX = () => {
    theInputRef.current.value = formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), balanceOfTarget, false)
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's wallet balance of the staking token
  const [balanceOfTarget, setBalanceOfTarget] = React.useState(0);
  const smartSetBalanceOfTarget = (balance) => {
    console.log(balance)
    setBalanceOfTarget(balance)
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's allowance (to the CHEF) of the staking token
  const [allowanceOfTarget, setAllowanceOfTarget] = React.useState(0);
  const smartSetAllowanceOfTarget = (allowance) => {
    console.log(allowance)
    setAllowanceOfTarget(allowance)
  }

  // onClick function for approving a given amount of the staking token
  const onClick_Approve = () => {
    setIsPending(true)
    pu.triggerApproval(
      theTargetADDY, 
      (allowance) => {
        smartSetAllowanceOfTarget(allowance)
        setIsPending(false)
      } )
  }

  // -- end of Logics
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----



  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Balances, Rewards, and Everything Else
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%----- 


  // every pool needs a balance and pendingReward state
  // the _name must be equivalent to the stake-name of the pool in contractConfigs.js

  const [poolBalance_agave, setPoolBalance_agave] = React.useState(0);
  const [pendingRewards_agave, setPendingRewards_agave] = React.useState(0);

  const [poolBalance_honey, setPoolBalance_honey] = React.useState(0);
  const [pendingRewards_honey, setPendingRewards_honey] = React.useState(0);

  const [poolBalance_tequila, setPoolBalance_tequila] = React.useState(0);
  const [pendingRewards_tequila, setPendingRewards_tequila] = React.useState(0);

  const [poolBalance_agaveTequila, setPoolBalance_agaveTequila] = React.useState(0);
  const [pendingRewards_agaveTequila, setPendingRewards_agaveTequila] = React.useState(0);

  const [poolBalance_honeyTequila, setPoolBalance_honeyTequila] = React.useState(0);
  const [pendingRewards_honeyTequila, setPendingRewards_honeyTequila] = React.useState(0);

  const [poolBalance_wxdaiTequila, setPoolBalance_wxdaiTequila] = React.useState(0);
  const [pendingRewards_wxdaiTequila, setPendingRewards_wxdaiTequila] = React.useState(0);

  const [poolBalance_moonwasp, setPoolBalance_moonwasp] = React.useState(0);
  const [pendingRewards_moonwasp, setPendingRewards_moonwasp] = React.useState(0);

  const [poolBalance_moonwaspWxdai, setPoolBalance_moonwaspWxdai] = React.useState(0);
  const [pendingRewards_moonwaspWxdai, setPendingRewards_moonwaspWxdai] = React.useState(0);

  const [poolBalance_moonwaspHoney, setPoolBalance_moonwaspHoney] = React.useState(0);
  const [pendingRewards_moonwaspHoney, setPendingRewards_moonwaspHoney] = React.useState(0);

  const [poolBalance_moonwaspXcomb, setPoolBalance_moonwaspXcomb] = React.useState(0);
  const [pendingRewards_moonwaspXcomb, setPendingRewards_moonwaspXcomb] = React.useState(0);

  const [poolBalance_moonwaspTequila, setPoolBalance_moonwaspTequila] = React.useState(0);
  const [pendingRewards_moonwaspTequila, setPendingRewards_moonwaspTequila] = React.useState(0);

  const [poolBalance_xcombTequila, setPoolBalance_xcombTequila] = React.useState(0);
  const [pendingRewards_xcombTequila, setPendingRewards_xcombTequila] = React.useState(0);

  // returns the string that when passed through eval(), will get or set the appropriate state
  const getStateString = (poolID, isSet = true, isBalance = true ) => {
    const prefix = (!isSet) ? "p" : "setP"
    const meat = (isBalance) ? "oolBalance" : "endingRewards"
    const suffix = "_" + poolConfigs[poolID.toString()]["stake-name"]
    return prefix + meat + suffix  
  }


  const triggerGetPoolBalance = (id) => {
    pu.getPoolBalance(
      id,
      (res) => {
        console.log(res)
        console.log("^^ pool balance")
        eval(getStateString(id, true, true) + "(res[0])")
      }
    )
  }
 

  const triggerGetPendingRewards = (id) => {
    pu.getPendingRewards(
      id,
      (res) => {
        console.log(res + " << should be rewards for pool >> "+id)
        eval(getStateString(id, true, false) + "(res)") 
      }
    )
  }




  const getAllRewards = () => { 
    let ids = Object.keys(poolConfigs)
    ids.forEach((x, index) => {
      triggerGetPendingRewards(index)
    })
  }


  const getAllPoolBalances = () => { 
    let ids = Object.keys(poolConfigs)
    ids.forEach((x, index) => {
      triggerGetPoolBalance(index)
    })
  }


  const letItRip = () => {
    getAllRewards()
    getAllPoolBalances()
    getEmissionRate()
    getTotalAlloc()
  }

  const [emissionRate, setTheEmissionRate] = React.useState(0)
       
  const getEmissionRate = () => {
    pu.getRate(
      (res) => {
        setTheEmissionRate(res)
      }
      )
  }

  const [totalAllocPoints, setTotalAllocPoints] = React.useState(0)
       
  const getTotalAlloc = () => {
    pu.getTotalAlloc(
      (res) => {
        setTotalAllocPoints(res / dec18)
      }
      )
  }
    
  // render the app you dumb motherfucker
  return (
    <div className="App">
      <header className="App-header">
        <img src={rewardToken["icon"]["default"]} className="App-logo" alt="logo" /> 
        <div className="App-name">Tequila Farm</div> 
      </header>
      
        
      <button className="metamask-btn" onClick={connectMM}>{mmBtnText}</button>

      <div className="farmland">
        
        {poolIDs.map(id => (
          <Pool
            totalAllocPoints={totalAllocPoints} 
            poolID={id}
            balance={eval(getStateString(id, false, true))}
            rewards={eval(getStateString(id, false, false))}
            minusFunction = {openInputViewAndDoStuff(id, "remove")}
            plusFunction={openInputViewAndDoStuff(id, "add")} 
            harvestFunction={returnHarvestFunction(id)}/>

          ))}
      
      </div>

      <div className={"theInput TOGGLE__theInput--" + theInputTOGGLE + " INTENT__theInput--" + theInputINTENT}>
        <input type="text" id="theInput" defaultValue="0" ref={theInputRef} />
        <button className="btn btn--max" onClick={setInputRefToMAX}>MAX</button>
        <div className="theInput__balance">Balance: <span>{formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), balanceOfTarget)}</span></div>
        <div className="theInput__allowance">Allowance: <span>{formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), allowanceOfTarget)}</span></div>
        <button className="btn btn--back" onClick={toggleInput}>back</button>
        <button className="btn btn--approve" onClick={onClick_Approve}>Approve This Amount</button>
        <button className="btn btn--deposit" onClick={triggerDeposit}>Deposit This Amount</button>
        <button className="btn btn--withdraw" onClick={triggerWithdraw}>Withdraw This Amount</button>
      </div>

      <div className={"pending pending--" + isPending}>Pending Transaction</div>

      <div className={"info"}>
        <a href="https://blockscout.com/xdai/mainnet/address/0xAb23368c8fF6e518AE4a114cc10d9a1aC29f9667/contracts" target="_blank">TheChef</a>
        <a href="https://twitter.com/tequilaxdai" target="_blank">Twitter</a>
        <a href="https://discord.gg/hpshbZh5" target="_blank">Discord</a>
        <a href="https://app.honeyswap.org/#/add/0x31587a605B4cEbe82eaF1e8c3fF60563B0642118/undefined" target="_blank">Honeyswap</a>
        <a href="https://moonwasp.io" target="_blank">Moonwasp</a>
        <a href="https://maxus.blog" target="_blank">Maxus</a>
        <p>This app is in like super-duper-early-pre-alpha mode. PUHHLLLEASSEEE do not ape your life savings into this. Use at your own risk, homie. Def not audited.</p>
        <p>Emission Rate: {formatTokenBalance(18,emissionRate)} TEQUILA/Block</p>
      </div>
  </div>
  );
}

export default App;
